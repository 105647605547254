import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import { AvatarUpload } from '../components/Test/Test'

export default () => {
  // useEffect(() => {
  //   TestAction.testRest()()
  // }, [])

  return (
    <Layout>
      <SEO title="Test" />
      <AvatarUpload/>

    </Layout>
  )
}
