import React from 'react'
import Slider from "react-slick";
import ReactPlayer from 'react-player'

export function AvatarUpload() {
  return (
    <ReactPlayer controls url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />

  );
}
